var generic = generic || {},
  site = site || {},
  Drupal = Drupal || {},
  drupalSettings = Drupal.settings || {};

(function ($) {
  Drupal.behaviors.countryChooserV1 = {
    attach: function (context) {
      var self = this;

      self.updateCountrySelected();
      var $nativeCountrySelect = $('select.site-header__country-select', context);
      var $nativeCountrySelectOptions = $nativeCountrySelect.find('option');
      var $countrySelectBoxSelected = $('a.site-header__country-select .selectBox-label', context);
      var $countrySelectBoxOptions = $(
        '.site-header__country-select-selectBox-dropdown-menu li',
        context
      );
      var selected_flag = $nativeCountrySelect.find(':selected').data('thumb-url');

      if (typeof selected_flag !== 'undefined') {
        $countrySelectBoxSelected.html(
          "<img class='flag_thumb' src='" +
            selected_flag +
            "' > - " +
            $countrySelectBoxSelected.html()
        );
      }

      for (
        var option_index = 0;
        option_index < $nativeCountrySelectOptions.length;
        option_index++
      ) {
        var flagUrl = $($nativeCountrySelectOptions[option_index]).data('thumb-url');
        var $selectBoxOption = $($countrySelectBoxOptions[option_index]);
        var $selectBoxOptionValue = $selectBoxOption.find('a').html();

        if (typeof flagUrl !== 'undefined') {
          $selectBoxOption
            .find('a')
            .html("<img class='flag_thumb' src='" + flagUrl + "' > - " + $selectBoxOptionValue);
        }
      }
      self.countryList();
    },
    countryList: function (context) {
      var $nativeCountrySelect = $('select.site-header__country-select', context);

      if (site.client.isMobile) {
        $('.js-footer-country-select').append($nativeCountrySelect);
      }
      $nativeCountrySelect.on('change', function (e) {
        // When a country is selected, open it in a new window
        e.preventDefault();
        var urlToOpen = $(this.options[this.selectedIndex]).data('url');

        document.location.href =
          document.location.protocol +
          '//' +
          urlToOpen +
          document.location.pathname +
          document.location.search;
      });
    },
    updateCountrySelected: function (context) {
      var self = this;
      var defaultLanguage = 'en';
      var domainUrl = window.location.hostname;
      var $countryChooserListItem = $('.js-gnav-country-chooser-list__item', context);
      var itemLanguage = '';

      $.each($countryChooserListItem, function (index, item) {
        itemLanguage = $(this).data().localeValue;
        if (defaultLanguage === itemLanguage) {
          $(item).addClass('gnav-country-chooser-list__item--default');
        }
      });

      if (
        drupalSettings.has_onelink_trans &&
        drupalSettings.onelink_translation_urls &&
        drupalSettings.onelink_translation_urls.default
      ) {
        defaultLanguage = drupalSettings.onelink_translation_urls.default;
      }
      var $nativeCountrySelect = $('select.site-header__country-select', context);
      var countrySelectOptions = $nativeCountrySelect.find('option');

      self.refreshCountrySelect($nativeCountrySelect, defaultLanguage);

      var updateOltLink = function (optionNode, languageNameOption) {
        if (drupalSettings.has_onelink_trans && drupalSettings.onelink_translation_urls) {
          var onelinkTranslationUrls = drupalSettings.onelink_translation_urls;

          $.each(onelinkTranslationUrls, function (index, value) {
            if (languageNameOption.indexOf(index) !== -1) {
              optionNode.attr('data-url', value.url);
              optionNode.data('url', value.url);
            }
          });
        }
      };

      countrySelectOptions.each(function () {
        var optionNode = $(this);
        var languageNameOption = optionNode.val();
        var prefixArray = languageNameOption.split(',');
        var findLocalebyUrl = false;

        updateOltLink(optionNode, languageNameOption);
        $.each(prefixArray, function (index, value) {
          if (domainUrl.indexOf(value) !== -1) {
            findLocalebyUrl = true;
          }
        });
        if (findLocalebyUrl) {
          self.refreshCountrySelect($nativeCountrySelect, languageNameOption);
        }
      });
    },
    refreshCountrySelect: function (countrySelect, optionCountry) {
      countrySelect.val(optionCountry);
      // userAgent regex was copied from line:35 of lib/jquery.selectBox.patched.js to fix iPad bugs
      if (
        !site.client.isMobile &&
        !navigator.userAgent.match(/iPad|iPhone|Android|IEMobile|BlackBerry/i)
      ) {
        countrySelect.selectBox('refresh');
      }
    }
  };
})(jQuery);
